import React from "react";
import { InputNumber } from "antd";
import "./style.scss";

const NumericInput = (props) => {
  // Its allows only numeric value
  const handleNumericKey = (e) => {
    const isNonNumericKey = /^\D$/.test(e.key);
    const isControlKey = e.ctrlKey;
    if (isNonNumericKey && !isControlKey) {
      e.preventDefault();
    }
  };

  return (
    <InputNumber
      type="tel"
      controls={false}
      keyboard={false}
      changeOnWheel={false}
      onKeyDown={handleNumericKey}
      {...props} // Pass other props to the input
    />
  );
};

export default NumericInput;
