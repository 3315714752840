import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Avatar,
  Modal,
  Flex,
  Col,
  Typography,
  notification,
} from "antd";
import "../dashboard/style.scss";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../utils";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import CustomButton from "../../custom/success-button";
import ViewDetails from "./view-details";

const { Title } = Typography;

const Dashboard = () => {
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();

  const [dashboardData, setDashboardData] = useState({});
  const [cardDetails, setCardDetails] = useState({
    isModal: false,
    selectedCard: {},
    fetchedData: [],
  });

  const cardList = [
    {
      content: "Profiles who are shortlisted by me",
      key: "get_profiles_shortlisted_by_me",
      fetchKey: "shortlisted_profile_details",
      title: "Shortlisted Profile",
      count: dashboardData.shortlisted_profile_count,
    },
    {
      content: "Profiles who have shortlisted my profile",
      key: "get_profiles_shortlisted_my_profile",
      fetchKey: "shortlisted_user_details",
      title: "Shortlisted By",
      count: dashboardData.ppl_shortlisted_my_profile_count,
    },
    {
      content: "Profiles which are viewed by me",
      key: "get_profiles_visited_by_me",
      fetchKey: "visited_profiles",
      title: "Profiles Visited",
      count: dashboardData.profiles_visited_count,
    },
    {
      content: "Profiles which viewed my profile",
      key: "get_visited_profiles_details",
      fetchKey: "visited_profiles",
      title: "Visited Profiles",
      count: dashboardData.visited_profiles_count,
    },
    {
      content: "Profile connections sent by me to other profiles",
      key: "get_pending_connection_request_profiles",
      fetchKey: "connection_requests",
      title: "Requested Connection",
      count: dashboardData.pending_connection_count,
    },
    {
      content: "Profile connections which are accepted by me",
      key: "get_accepted_connection_request_profiles",
      fetchKey: "connection_requests",
      title: "Accepted Connection",
      count: dashboardData.accepted_connection_count,
    },
    {
      content: "Request which are sent to me by other profiles",
      key: "get_received_connection_request_profiles",
      fetchKey: "connection_requests",
      title: "Received  Connections",
      count: dashboardData.received_connection_count,
    },
    {
      content: "Contact details viewed by me",
      key: "get_contact_detail_viewed_profiles",
      fetchKey: "profile_list",
      title: "Viewed Contacts",
      count: dashboardData.contact_viewed_count,
    },
    {
      content: "Profiles viewed my contact details",
      key: "get_profiles_viewed_my_contact",
      fetchKey: "profile_list",
      title: "My Contact Viewers",
      count: dashboardData.profiles_viewed_my_contact_count,
    },
  ];

  const handleCancel = () => {
    setCardDetails({
      isModal: false,
      selectedCard: {},
      fetchedData: [],
    });
  };

  const fetchCardDetails = (details) => {
    dispatch(startLoading());
    setCardDetails((prev) => ({ ...prev, selectedCard: details }));
    const cardDetailsURL = "dashboard_card_details";
    const cardDetailsPayload = {
      card_name: details.key,
    };
    authAxios
      .post(cardDetailsURL, cardDetailsPayload)
      .then((reponse) => {
        setCardDetails({
          isModal: true,
          selectedCard: details,
          fetchedData: reponse.data.data[details.fetchKey],
        });
      })
      .catch((error) =>
        notification.error({
          message: error.response.data.message || "Please try again later.",
        })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const fetchDashboardData = () => {
    dispatch(startLoading());
    const url = "get_dashboard_data";
    authAxios
      .get(url)
      .then((response) => {
        setDashboardData(response.data.data);
      })
      .catch((error) =>
        notification.error({
          message: error.response.data.message || "Please try again later.",
        })
      )
      .finally(() => dispatch(stopLoading()));
  };

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Flex vertical gap={54} className="dashboard-container">
        <Card className="dashboard-header">
          <Row align="middle" gutter={[16, 16]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={80}>
              <Flex align="center" gap={8}>
                <span>
                  <Avatar
                    size={50}
                    style={{ backgroundColor: "#05870e", color: "#fff" }}
                  >
                    {dashboardData.profile_name?.[0] || ""}
                  </Avatar>
                </span>
                <Title level={4}>
                  {dashboardData.profile_name || ""}{" "}
                  {dashboardData.profile_id
                    ? `(${dashboardData.profile_id})`
                    : ""}
                </Title>
              </Flex>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} align="center">
              <Title level={5}>
                Membership Type : {dashboardData.membership_type}{" "}
                {dashboardData.membership_type === "BASIC" && (
                  <span className="membership-type">(FREE)</span>
                )}
                <br />
                Membership End Date :{" "}
                {dashboardData.membership_end_date
                  ? dashboardData.membership_end_date
                  : "  -  "}
              </Title>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} align="center">
              <Title level={5}>
                Account Status :
                {dashboardData.account_status ? " Active" : " InActive"}
                <br />
                Remaining Contact Credits :{" "}
                {dashboardData.remaining_contact_credits
                  ? dashboardData.remaining_contact_credits
                  : 0}
              </Title>
            </Col>
          </Row>
        </Card>
        <Flex
          align="center"
          className="dashboard-body"
          gap={45}
          justify="space-evenly"
          wrap
        >
          {cardList.map((list) => (
            <Card className="dashboard-card" key={list.key} hoverable>
              <Row gutter={[16, 16]}>
                <Col span={24} align="center">
                  <Avatar size={90}>{list.count || 0}</Avatar>
                </Col>
                <Col span={24} align="center">
                  <Title level={4}>{list.title || ""}</Title>
                  <span className="content">{list.content || ""}</span>
                </Col>
                <Col span={24} align="center">
                  <CustomButton
                    success
                    type="primary"
                    onClick={() => fetchCardDetails(list)}
                    size="large"
                    disabled={list.count < 1}
                  >
                    View All
                  </CustomButton>
                </Col>
              </Row>
            </Card>
          ))}
        </Flex>
      </Flex>
      <Modal
        centered
        className="dashboard-details-container"
        footer={null}
        maskClosable={false}
        open={cardDetails.isModal}
        onCancel={handleCancel}
        title={
          <>
            <Title level={4}>{cardDetails.selectedCard.title}</Title>
            <p>{cardDetails.selectedCard.content}</p>
          </>
        }
        width={600}
      >
        <ViewDetails cardDetails={cardDetails} />
      </Modal>
    </>
  );
};

export default Dashboard;
