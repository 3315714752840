import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Typography,
  notification,
} from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAxios, encryptData } from "../../utils";
import { BASE_URL } from "../../config";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import LoginImage from "../../image/login.png";
import logo from "../../image/vkvmlogo-2.png";
import "./style.scss";

const { Password } = Input;

const Login = () => {
  const [form] = Form.useForm();
  const [isForgot, setIsForgot] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleIsForgot = () => {
    form.resetFields();
    setIsForgot((prevState) => !prevState);
  };

  const handleForgotPassword = (values) => {
    dispatch(startLoading());
    const url = "forgot_password";
    const forgotPayload = {
      user_name: values.username,
    };
    UseAxios.post(url, forgotPayload)
      .then((response) => {
        notification.success({
          message: response.data.message,
        });
        handleIsForgot();
      })
      .catch((error) =>
        notification.error({
          message: error.response.data.message,
        })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleForgotPwdConfirm = (values) => {
    Modal.confirm({
      centered: true,
      className: "forgot-password-confirm",
      title: "Are you sure to reset your password?",
      okText: "Cancel",
      cancelText: "Confirm",
      onCancel: () => handleForgotPassword(values),
      cancelButtonProps: {
        type: "primary",
      },
      okButtonProps: {
        danger: true,
      },
      width: "auto",
    });
  };

  const handleLogin = (payload) => {
    const { username, password, is_pp_accepted } = payload;
    dispatch(startLoading());
    const url = "login";
    const encryptedPassword = encryptData(password);
    const loginPayload = {
      username,
      is_pp_accepted,
      login_url: BASE_URL + url,
      password: encryptedPassword,
    };

    UseAxios.post(url, loginPayload)
      .then((response) => {
        const token = response.data.data.token || "";
        const userDetails = response.data.data || "";
        localStorage.setItem("userDetails", JSON.stringify(userDetails));
        localStorage.setItem(
          "account_status",
          JSON.stringify(userDetails.profile_details)
        );
        localStorage.setItem("accessToken", token.access);
        localStorage.setItem("refreshToken", token.refresh);
        navigate("/search");
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const onFinish = (formValues) => {
    if (isForgot) {
      handleForgotPwdConfirm(formValues);
    } else {
      handleLogin(formValues);
    }
  };

  useEffect(() => localStorage.clear(), []);

  return (
    <>
      <Flex
        justify="center"
        align="center"
        gap="middle"
        className="login-container"
      >
        <Col xs={0} sm={0} md={0} lg={13} className="image-container">
          <Image src={LoginImage} preview={false} alt="login-img" />
        </Col>
        <Flex vertical gap="middle" className="form-container">
          <Typography.Title level={3}>
            <Image src={logo} alt="logo" width={64} preview={false} /> <br />
            Namma Kongu Vellala Gounder Matrimony <br />
            Welcomes You
          </Typography.Title>
          <Card bordered>
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                label="Username / Email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your username / email",
                  },
                ]}
              >
                <Input placeholder="Enter your Username / Email" />
              </Form.Item>
              {!isForgot && (
                <>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your password",
                      },
                    ]}
                  >
                    <Password placeholder="Enter your password" />
                  </Form.Item>
                  <Form.Item
                    name="is_pp_accepted"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  "Check the Privacy Policy & Terms and Conditions to proceed further."
                                )
                              ),
                      },
                    ]}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      I agree to the{" "}
                      <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href="/terms-and-conditions" target="_blank">
                        Terms and Conditions
                      </a>{" "}
                      of NKVG Matrimony.
                    </Checkbox>
                  </Form.Item>
                  <Col align="end" className="forgot-password">
                    <span onClick={handleIsForgot}>Forgot Password?</span>
                  </Col>
                </>
              )}
              <Flex vertical gap="small">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                {isForgot && (
                  <>
                    <Button danger type="primary" onClick={handleIsForgot}>
                      Go to Login
                    </Button>
                  </>
                )}
              </Flex>
            </Form>
            {!isForgot && (
              <>
                <br />
                <Flex justify="center" gap="small">
                  <span>New to NKVG Matrimony?</span>
                  <NavLink className="register-link" to="/register">
                    Register Now
                  </NavLink>
                </Flex>
              </>
            )}
          </Card>
        </Flex>
      </Flex>
    </>
  );
};

export default Login;
