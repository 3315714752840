import React from "react";
import { Card, Col, Image, Row } from "antd";
import "./style.scss";

import homeImage from "../../image/home.jpg";

const Home = () => {
  return (
    <Row justify="center" className="home-container">
      <Col lg={24} xl={16} align="center">
        <Card bordered>
          <Image
            src={homeImage}
            preview={false}
            alt="home-image"
            style={{ width: "90%" }}
          />
          <br />
          <br />
          <div>
            பத்து பொருத்தங்களைப் பார்த்து,
            <br />
            ஒன்பது கோள்நிலைகளை அறிந்து,
            <br />
            எட்டு திசைகளில் இருந்தும் உறவை அழைத்து,
            <br />
            ஏழு அடி எடுத்து வைத்து,
            <br />
            அறுசுவை உணவு படைத்து,
            <br />
            பஞ்சபூதங்களும் சாட்சியாக,
            <br />
            நான்கு வேதங்கள் முழங்க,..
            <br />
            மூன்று முடிச்சுகளால்,
            <br />
            இரு மனங்கள் ஒன்று சேரும் ஒரு அற்புத பந்தத்தின் உறவே திருமணம்.
          </div>
          <br />
          <div>
            இறைவன் ஆணையிட்ட விதியின்படி, எங்கோ பிறந்து வாழ்ந்த, என்னுடன் பிறவா
            தோழன், தோழியின் இரு இதயங்களை இணைக்க
          </div>
          <br />
          <div style={{ color: "#389e0d", fontSize: "larger" }}>
            எங்களின் “நம்ம கொங்கு வேளாள கவுண்டர்” மேட்ரிமோனியில் இணைந்திடுவீர்!
            வளமான வாழ்வை வாழ்ந்திடுவீர்!
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Home;
