import React, { useEffect, useState } from "react";
import {
  Col,
  Menu,
  Typography,
  Avatar,
  Button,
  Popover,
  Tooltip,
  Flex,
  Image,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  CloseOutlined,
  MenuOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../utils";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import webicon from "../../image/vkvmlogo.png";
import "./style.scss";

const { Title } = Typography;

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();

  const [isMenuPopover, setIsMenuPopver] = useState(false);
  const [subMenu, setSubMenu] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);

  const isSearchPage = /^\/search\/.+$/.test(window.location.pathname);

  const handleMenuPopOver = (value) => {
    setSubMenu([]);
    setIsMenuPopver(value);
  };

  const handleSubMenu = (key) => {
    setSubMenu(key);
  };

  const handleLogout = () => {
    dispatch(startLoading());
    const url = "logout";
    const refresh_token = localStorage.getItem("refreshToken");
    const payload = {
      refresh_token,
    };

    authAxios
      .post(url, payload)
      .then(() => navigate("/login"))
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  const handleMenuSelect = ({ key }, isMobile) => {
    if (isMobile) {
      handleMenuPopOver(false);
    }
    if (key === "/logout") {
      handleLogout();
    } else {
      navigate(key);
    }
  };

  const items = [
    isAdmin
      ? {
          key: "/admin",
          label: (
            <Tooltip placement="left" title="admin">
              <Avatar
                style={{ backgroundColor: "#87d068" }}
                icon={<UserSwitchOutlined />}
              />
            </Tooltip>
          ),
          children: [
            {
              key: "/admin/dashboard",
              label: "Dashboard",
            },
            {
              key: "/admin/profile-verification",
              label: "Profile Verification",
            },
            {
              key: "/report",
              label: "Report",
              children: [
                {
                  key: "/admin/report/reported-issues",
                  label: "Reported Issues",
                },
                {
                  key: "/admin/report/reported-account",
                  label: "Reported Account",
                },
                {
                  key: "/admin/report/inactive-profiles",
                  label: "Inactive Profiles",
                },
              ],
            },
            {
              key: "/admin/deactivated-profiles",
              label: "Deactivated Profiles",
            },
            { key: "/admin/delete-profile", label: "Delete Profile" },
            // { key: "/memberShipUpdate", label: "MemberShip Update" },
          ],
        }
      : null,
    { key: "/", label: "Home" },
    isLoggedIn
      ? {
          key: "/dashboard",
          label: isLoggedIn ? "Dashboard" : null,
        }
      : { key: "/register", label: "Register" },
    isAdmin && { key: "/register", label: "Register" },
    isLoggedIn ? { key: "/search", label: "Search" } : null,
    { key: "/payment", label: "Payment" },
    { key: "/contact", label: "Contact" },
    !isLoggedIn && { key: "/about", label: "About Us" },
    {
      key: "/login",
      label: isLoggedIn ? (
        <Avatar
          style={{ backgroundColor: "#87d068" }}
          icon={<UserOutlined />}
        />
      ) : (
        "Login"
      ),
      children: isLoggedIn
        ? [
            { key: "/profile", label: "Profile Settings" },
            { key: "/reportissue", label: "Report Issue" },
            { key: "/about", label: "About Us" },
            { key: "/logout", label: "Logout" },
          ]
        : null,
    },
  ];

  const handleTabClose = () => window.close();

  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem("accessToken"));
    setIsAdmin(
      JSON.parse(localStorage.getItem("userDetails"))?.profile_details
        ?.is_admin_user
    );
  }, []);

  return (
    <Flex align="center" justify="space-between" className="header-container">
      <Flex
        align="center"
        className="logo-container"
        gap={8}
        onClick={() => handleMenuSelect({ key: "/" })}
      >
        <Image height={36} preview={false} src={webicon} />
        <Title level={3}>
          NKVG Matrimony <sup>*Trial</sup>
        </Title>
      </Flex>
      {isSearchPage ? (
        <Button
          className="close-icon"
          danger
          icon={<CloseOutlined />}
          onClick={handleTabClose}
          shape="circle"
        />
      ) : (
        <>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={{
              span: 16,
              flex: "630px",
            }}
          >
            <Menu
              items={items}
              mode="horizontal"
              onClick={handleMenuSelect}
              className="web-menu"
              defaultSelectedKeys={[window.location.pathname]}
            />
          </Col>
          <Col xs={2} sm={2} md={1} lg={0} xl={0} xxl={0}>
            <Popover
              arrow={false}
              trigger="click"
              open={isMenuPopover}
              placement="bottomRight"
              overlayClassName="mobile-menu"
              onOpenChange={handleMenuPopOver}
              content={
                <Menu
                  mode="inline"
                  items={items}
                  openKeys={subMenu}
                  onOpenChange={handleSubMenu}
                  onClick={(event) => handleMenuSelect(event, true)}
                  defaultSelectedKeys={[window.location.pathname]}
                />
              }
            >
              <Button
                type="text"
                icon={<MenuOutlined />}
                className="menu-icon"
              />
            </Popover>
          </Col>
        </>
      )}
    </Flex>
  );
};

export default Header;
