import { Card } from "antd";
import { Outlet } from "react-router-dom";

const PolicyLayout = () => {
  return (
    <Card>
      <Outlet />
    </Card>
  );
};

export default PolicyLayout;
