import { useEffect, useState } from "react";
import {
  Table,
  Form,
  Col,
  Input,
  Button,
  Row,
  Modal,
  Select,
  notification,
  Flex,
  Pagination,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loaderReducer";
import CustomButton from "../../../custom/success-button";
import "./style.scss";

const { Option } = Select;

const getRefreshToken = () => localStorage.getItem("refreshToken");

const ProfileVerifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [profileData, setProfileData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const profile_verification_status = ["Requested", "Accepted", "Rejected"];
  const Approval_status = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
  ];
  const initialValues = { request_status: "Requested", profile_id: "" };

  const columns = [
    {
      title: "Name",
      dataIndex: "profile_name",
    },
    {
      title: "Profile ID",
      dataIndex: "profile_username",
    },
    {
      title: "Requested Status",
      dataIndex: "request_status",
      render: (data) => <span className={data}>{data}</span>,
    },
    {
      title: "Created At",
      dataIndex: "requested_at",
    },
    {
      title: "Admin Name",
      dataIndex: "approved_admin_name",
    },
    {
      title: "Admin ID",
      dataIndex: "approved_admin_username",
    },
    {
      title: "Processed At",
      dataIndex: "approved_at",
    },
    {
      title: "Notes",
      dataIndex: "admin_notes",
    },

    {
      title: "Action",
      render: (data) => (
        <Flex gap={8} justify="center" wrap>
          {data.request_status !== "Accepted" ? (
            <CustomButton
              success
              type="primary"
              onClick={() => showModal(data)}
            >
              Approval
            </CustomButton>
          ) : null}
          <Button
            type="primary"
            onClick={() => {
              handleViewProfile(data?.profile_username);
            }}
          >
            View Profile
          </Button>
        </Flex>
      ),
    },
  ];

  const showModal = (data) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setSelectedData({});
    setIsModalOpen(false);
    form2.resetFields();
  };

  const fetchProfileData = (payload) => {
    dispatch(startLoading());
    const url = "/view_profiles_to_verify";

    authAxios
      .post(url, payload)
      .then((response) => {
        setProfileData(response.data.data.results);
        setPagination(response.data.data.pagination_data);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const onFinishSearch = (formValues) => {
    const payload = {
      search_profile_id: formValues.profile_id,
      request_status: formValues.request_status,
      page_number: 1,
      records_per_page: 10,
    };
    fetchProfileData(payload);
  };
  const handleSessionExpired = () => {
    alert("Your session has expired. Please log in again.");
    navigate("/login");
  };

  const handleViewProfile = (profile_id) => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      window.open(`/search/${profile_id}`, "_blank");
    } else {
      handleSessionExpired();
    }
  };

  const handlePageChange = (pageNo) => {
    const { request_status, profile_id } = form1.getFieldsValue();
    const payload = {
      search_profile_id: profile_id,
      request_status,
      page_number: pageNo,
      records_per_page: 10,
    };
    fetchProfileData(payload);
  };

  const onFinish = (formValues) => {
    dispatch(startLoading());
    const { request_status } = form1.getFieldsValue();

    const url = "/process_profile_registration_request";
    const payload = {
      registration_request_id: selectedData.id,
      approval_status: formValues.approval_status,
      approval_notes: formValues.Notes,
      approved_admin_name: selectedData.profile_name,
      approved_admin_username: selectedData.profile_username,
    };

    authAxios
      .post(url, payload)
      .then((res) => {
        const payload = {
          search_profile_id: "",
          request_status,
          page_number: 1,
          records_per_page: 10,
        };
        fetchProfileData(payload);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
    handleCancel();
  };

  useEffect(() => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const payload = {
        search_profile_id: "",
        request_status: ["Requested"],
        page_number: 1,
        records_per_page: 10,
      };
      fetchProfileData(payload);
    } else {
      handleSessionExpired();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography.Title level={3} className="page-title">
        Profile Verification
      </Typography.Title>
      <Form
        autoComplete="off"
        className="profile-verification-search"
        initialValues={initialValues}
        form={form1}
        onFinish={onFinishSearch}
      >
        <Row align="middle" gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item
              label="Profile Verification Status"
              name="request_status"
              rules={[
                {
                  required: true,
                  message: "Please select profile verification status",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select profile verification status"
              >
                {profile_verification_status.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item name="profile_id" label="Search Profile ID">
              <Input placeholder="Enter Profile ID" />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        className="profile-verification-table"
        columns={columns}
        dataSource={profileData}
        pagination={false}
        bordered
        scroll={{
          x: 920,
        }}
        rowKey="profile_username"
      />
      <br />
      <Pagination
        className="profile-verification-pagination"
        total={pagination.total_objects}
        current={pagination.requested_page_number}
        onChange={handlePageChange}
      />
      <Modal
        className="request-approval-modal"
        title="Request Approval"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="user-details">
          <strong>
            Profile ID: <span>{selectedData.profile_username}</span>
          </strong>
        </p>
        <p className="user-details">
          <strong>
            Name: <span>{selectedData.profile_name}</span>
          </strong>
        </p>
        <Form
          autoComplete="off"
          form={form2}
          initialValues={selectedData}
          onFinish={onFinish}
        >
          <Form.Item initialValue="" label="Notes" name="Notes">
            <Input.TextArea
              rows={5}
              count={{
                show: true,
              }}
              maxLength={250}
              placeholder="Enter the Notes"
            />
          </Form.Item>
          <Form.Item
            name="approval_status"
            label="Approval Status"
            rules={[
              {
                required: true,
                message: "Please select profile verification status",
              },
            ]}
          >
            <Select placeholder="Select your approval status">
              {Approval_status?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Flex gap={8} justify="center">
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
            <Button danger onClick={handleCancel} type="primary">
              Cancel
            </Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};

export default ProfileVerifications;
