import { useEffect, useState } from "react";
import { Card, Modal, notification } from "antd";
import { UseAuthAxios } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import PasswordUpdate from "./password-update";
import DeleteAccount from "./delete-account";
import ProfileCard from "./profile-card";
import "./style.scss";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();
  const [accountStatus, setAccountStatus] = useState({});
  const [isActions, setIsActions] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const handleAction = (value) => {
    setIsActions(value);
  };

  const handleActionCancel = () => {
    setIsActions(null);
  };

  const handleAccountStatus = (responseData) => {
    const newAccountStatus = { ...accountStatus, ...responseData.data };
    localStorage.setItem("account_status", JSON.stringify(newAccountStatus));
    setAccountStatus(newAccountStatus);
  };

  const handleDelete = () => {
    navigate("/login");
  };

  const handleAccountAction = (key, payload) => {
    dispatch(startLoading());
    const { account_delete_status } = accountStatus;
    const method = {
      1: "post",
      2: "post",
      3: account_delete_status ? "post" : "delete",
    };
    const url = {
      1: "change_password",
      2: "set_user_account_status",
      3: account_delete_status
        ? "cancel_profile_delete_request"
        : "request_profile_delete",
    };

    const success = {
      1: handleActionCancel,
      2: handleAccountStatus,
      3: handleDelete,
    };

    authAxios({
      method: method[key],
      url: url[key],
      data: payload,
    })
      .then((response) => {
        success[key](response.data);
        notification.success({ message: response.data.message });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  const handleConfirm = (actionKey, payload) => {
    const { account_status, account_delete_status } = accountStatus;

    const titleMessages = {
      1: "Are you sure to change profile password?",
      2: `Are you sure you want to ${
        account_status ? "deactivate" : "activate"
      } your profile?`,
      3: account_delete_status ? (
        "Are you sure you want to cancel your profile delete request?"
      ) : (
        <div>
          Are you sure you want to delete your profile?
          <br />
          Please note that all your profile details will be permanently deleted
          if the request is approved by the admin.
          <br />
          Once deleted, you will not be able to recover your profile.
        </div>
      ),
    };

    Modal.confirm({
      centered: true,
      className: "profile-confirm-modal",
      title: titleMessages[actionKey],
      okText: "Cancel",
      cancelText: "Confirm",
      keyboard: false,
      onCancel: () => handleAccountAction(actionKey, payload),
      cancelButtonProps: {
        type: "primary",
      },
      okButtonProps: {
        danger: true,
      },
      width: "auto",
    });
  };

  useEffect(() => {
    setAccountStatus(JSON.parse(localStorage.getItem("account_status")));
  }, []);

  return (
    <Card className="profile-container">
      {isActions === "changePassword" ? (
        <PasswordUpdate
          handleConfirm={handleConfirm}
          handleActionCancel={handleActionCancel}
        />
      ) : isActions === "deleteProfile" ? (
        <DeleteAccount
          accountStatus={accountStatus}
          handleActionCancel={handleActionCancel}
          handleConfirm={handleConfirm}
        />
      ) : (
        <ProfileCard
          accountStatus={accountStatus}
          handleAction={handleAction}
          handleConfirm={handleConfirm}
          userDetails={userDetails}
        />
      )}
    </Card>
  );
};

export default Profile;
