import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import "../loader/loader.scss";

const Loader = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);

  if (!isLoading) return null;
  return (
    <>
      <div className="loader-overlay">
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 100,
                color: "#009900",
                className: "loader-spin",
              }}
              spin
            />
          }
        />
      </div>
    </>
  );
};

export default Loader;
