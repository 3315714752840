import { Descriptions } from "antd";
import "./style.scss";

const ProfileDetails = (properties) => {
  const { profileData } = properties;
  const {
    basic_details,
    horoscope_details,
    physical_details,
    education_details,
    contact_details,
    family_details,
    partner_preference,
  } = profileData;

  const titles = [
    "Basic Details",
    "Horoscope Details",
    "Physical Details",
    "Education Details",
    "Contact Details",
    "Family Details",
    "Partner Preference",
  ];

  const descriptionItems = {
    "Basic Details": [
      { key: "age", label: "Age", children: basic_details?.age },
      {
        key: "gender",
        label: "Gender",
        children: basic_details?.gender,
      },
      {
        key: "marital_status",
        label: "Marital Status",
        children: basic_details?.marital_status,
      },
      {
        key: "location",
        label: "Location",
        children: basic_details?.location,
      },
      { key: "kulam", label: "Kulam", children: basic_details?.kulam },
      { key: "dob", label: "Dob", children: basic_details?.dob },
      {
        key: "account_created_by",
        label: "Account Created By",
        children: basic_details?.account_created_by,
      },
      { key: "kovil", label: "Kovil", children: basic_details?.kovil },
      {
        key: "kovil_location",
        label: "Kovil Location",
        children: basic_details?.kovil_location,
      },
    ],
    "Horoscope Details": [
      { key: "rasi", label: "Rasi", children: horoscope_details?.rasi },
      {
        key: "nachathiram",
        label: "Nachathiram",
        children: horoscope_details?.star,
      },
      { key: "padham", label: "Padham", children: horoscope_details?.padham },
      { key: "lagnam", label: "Lagnam", children: horoscope_details?.lagnam },
      {
        key: "is_sevvai",
        label: "Sevvai",
        children: horoscope_details?.is_sevvai ? "Yes" : "No",
      },
      {
        key: "is_ragu_kedhu",
        label: "Ragu/Kedhu",
        children: horoscope_details?.is_ragu_kedhu ? "Yes" : "No",
      },
    ],
    "Physical Details": [
      {
        key: "height",
        label: "Height",
        children: `${physical_details?.height_in_cm}/${physical_details?.height_in_inch}`,
      },
      {
        key: "weight_in_kg",
        label: "Weight in Kg",
        children: physical_details?.weight_in_kg,
      },
      {
        key: "body_type",
        label: "Body Type",
        children: physical_details?.body_type,
      },
      {
        key: "skinTone",
        label: "Skin Tone",
        children: physical_details?.skin_type,
      },
      {
        key: "blood_group",
        label: "Blood Group",
        children: physical_details?.blood_group,
      },
      { key: "diet", label: "Diet", children: physical_details?.diet },
    ],
    "Education Details": [
      {
        key: "education",
        label: "Education",
        children: education_details?.education,
      },
      {
        key: "education_details",
        label: "Education Details",
        children: education_details?.education_details,
      },
      {
        key: "job_title",
        label: "Job Title",
        children: education_details?.job_title,
      },
      {
        key: "company_name",
        label: "Company Name",
        children: education_details?.company_name,
      },
      {
        key: "currently_employed_country",
        label: "Currently Employed Country",
        children: education_details?.currently_employed_country,
      },
      {
        key: "occupation",
        label: "Occupation",
        children: education_details?.occupation,
      },
      {
        key: "college",
        label: "College",
        children: education_details?.college,
      },
      {
        key: "monthly_income_in_rs",
        label: "Monthly Income (₹)",
        children: education_details?.monthly_income_in_rs,
      },
    ],
    "Contact Details": [
      {
        key: "country",
        label: "Country",
        children: contact_details?.country,
      },
      { key: "state", label: "State", children: contact_details?.state },
      { key: "city", label: "City", children: contact_details?.city },
      {
        key: "number_1",
        label: "Phone Number 1",
        children: contact_details?.number_1,
      },
      {
        key: "number_2",
        label: "Phone Number 2",
        children: contact_details?.number_2,
      },
    ],
    "Family Details": [
      {
        key: "father_name",
        label: "Father's Name",
        children: family_details?.father_name,
      },
      {
        key: "father_occupation",
        label: "Father's Occupation",
        children: family_details?.father_occupation,
      },
      {
        key: "mother_name",
        label: "Mother's Name",
        children: family_details?.mother_name,
      },
      {
        key: "mother_occupation",
        label: "Mother's Occupation",
        children: family_details?.mother_occupation,
      },
      {
        key: "no_of_sisters",
        label: "Number of Sisters",
        children: family_details?.no_of_sisters,
      },
      {
        key: "no_sisters_married",
        label: "Number of Sisters Married",
        children: family_details?.no_sisters_married,
      },
      {
        key: "no_of_brothers",
        label: "Number of Brothers",
        children: family_details?.no_of_brothers,
      },
      {
        key: "no_brothers_married",
        label: "Number of Brothers Married",
        children: family_details?.no_brothers_married,
      },
      {
        key: "family_status",
        label: "Family Status",
        children: family_details?.family_status,
      },
      {
        key: "family_value",
        label: "Family Value",
        children: family_details?.family_value,
      },
      {
        key: "family_type",
        label: "Family Type",
        children: family_details?.family_type,
      },
      {
        key: "family_income",
        label: "Monthly Family Income (₹)",
        children: family_details?.family_income,
      },
      {
        key: "about_family",
        label: "About Family",
        children: family_details?.about_family,
      },
      {
        key: "asset_details",
        label: "Asset Details",
        children: family_details?.asset_details,
      },
      {
        key: "total_asset_value",
        label: "Total Asset Value",
        children: family_details?.total_asset_value,
      },
      {
        key: "native_city",
        label: "Native City",
        children: family_details?.native_city,
      },
      {
        key: "is_own_house",
        label: "Own House",
        children: family_details?.is_own_house,
      },
    ],
    "Partner Preference": [
      {
        key: "from_age",
        label: "From Age",
        children: partner_preference?.from_age,
      },
      {
        key: "to_age",
        label: "To Age",
        children: partner_preference?.to_age,
      },
      {
        key: "marital_status",
        label: "Marital Status",
        children: partner_preference?.marital_status,
      },
      {
        key: "skinTone",
        label: "Skin Tone",
        children: partner_preference?.complexion,
      },
      {
        key: "education",
        label: "Education",
        children: partner_preference?.education,
      },
      {
        key: "occupation",
        label: "Occupation",
        children: partner_preference?.occupation,
      },
      {
        key: "is_horoscope_match",
        label: "Horoscope Match",
        children: partner_preference?.is_horoscope_match,
      },
      {
        key: "is_sevvai",
        label: "Sevvai",
        children: partner_preference?.is_sevvai,
      },
      {
        key: "is_ragu_kethu",
        label: "Ragu Kethu",
        children: partner_preference?.is_ragu_kethu,
      },
      {
        key: "is_brother",
        label: "Brother",
        children: partner_preference?.is_brother,
      },
      {
        key: "is_sisters",
        label: "Sisters",
        children: partner_preference?.is_sisters,
      },
      {
        key: "food_preference",
        label: "Food Preference",
        children: partner_preference?.food_preference,
      },
      {
        key: "description",
        label: "Description",
        children: partner_preference?.description,
      },
    ],
  };

  return (
    <>
      {titles.map((data) => (
        <Descriptions
          bordered
          key={data}
          title={data}
          items={descriptionItems[data]}
          className="user-profile-container"
          column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
        />
      ))}
    </>
  );
};

export default ProfileDetails;
