import { Button, Flex, Image, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { getProfileImageUrl } from "../helper";

const { Title } = Typography;

const ProfileCard = (properties) => {
  const { accountStatus, handleAction, handleConfirm, userDetails } =
    properties;

  const isAccountStatus = accountStatus?.account_status;
  const isDeleteStatus = accountStatus?.account_delete_status;

  const payload = {
    account_status: isAccountStatus ? "deactivate" : "activate",
  };

  const navigate = useNavigate();

  const handleViewMyProfile = () => {
    const isLoggedIn = localStorage.getItem("accessToken");
    if (isLoggedIn) {
      window.open(`/search/${userDetails.user_id}`, "_blank");
    } else {
      alert("Your session has expired. Please log in again.");
      navigate("/login");
    }
  };

  return (
    <Flex align="center" gap={24} vertical wrap>
      <Image
        src={getProfileImageUrl(userDetails)}
        alt="profile-pic"
        width={180}
        height={180}
        className="profile-photo"
        preview={false}
      />
      <Title align="center" level={3}>
        {userDetails.user_name || ""} ({userDetails.user_id || ""})
      </Title>
      <Space direction="vertical" align="center">
        <Button
          onClick={() => navigate("/myphotos")}
          size="large"
          shape="round"
          type="primary"
        >
          My Photos
        </Button>
        <Button
          onClick={handleViewMyProfile}
          size="large"
          shape="round"
          type="primary"
        >
          View My Profile
        </Button>
        <Button
          shape="round"
          size="large"
          type="primary"
          onClick={() => handleAction("changePassword")}
        >
          Change Password
        </Button>
        <Button
          onClick={() => handleConfirm(2, payload)}
          size="large"
          shape="round"
          type="primary"
        >
          {isAccountStatus ? "Deactivate" : "Activate"} Profile
        </Button>
        <Button
          danger
          onClick={() =>
            isDeleteStatus
              ? handleConfirm(3, {})
              : handleAction("deleteProfile")
          }
          size="large"
          shape="round"
          type="primary"
        >
          {isDeleteStatus ? "Cancel Delete Profile" : "Delete Profile"}
        </Button>
      </Space>
    </Flex>
  );
};

export default ProfileCard;
