import React, { useState } from "react";
import { Button, Flex, Modal, Upload, message, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UseAxiosUpload } from "../../utils";

const { Dragger } = Upload;

const PhotoUpload = (properties) => {
  const {
    isPhotoUpload,
    handleGetUserPhotos,
    handleUploadModal,
    maxUploadLimit,
    userPhotos,
  } = properties;

  const [isUploading, setIsUploading] = useState(false);
  const [photoList, setPhotoList] = useState([]);

  const handleFileUpload = ({ file, fileList }) => {
    const { type, size } = file;
    const isValidType = ["image/jpeg", "image/jpg", "image/png"].includes(type);

    if (!isValidType) {
      message.error("You can only upload JPEG/JPG/PNG files");
      return Upload.LIST_IGNORE;
    }

    if (size >= 5 * 1024 * 1024) {
      message.error("Photo size must be smaller than 5MB");
      return Upload.LIST_IGNORE;
    }

    return setPhotoList(fileList);
  };

  const handlePhotoSave = () => {
    setIsUploading(true);
    const formData = new FormData();
    photoList.forEach((file) => {
      formData.append("files", file.originFileObj);
    });
    const url = "/upload_file";
    UseAxiosUpload()
      .post(url, formData)
      .then((response) => {
        handleGetUserPhotos(response);
        handleCancel();
      })
      .catch((error) =>
        notification.error({
          message: error.response.data.message || "Please try again later.",
        })
      )
      .finally(() => setIsUploading(false));
  };

  const handleCancel = () => {
    setPhotoList([]);
    handleUploadModal();
  };

  return (
    <>
      <Modal
        centered
        className="photo-upload-modal"
        closable={false}
        destroyOnClose
        footer={
          <Flex gap={8} justify="center">
            <Button
              disabled={!photoList.length}
              loading={isUploading}
              onClick={handlePhotoSave}
              size="large"
              type="primary"
            >
              Save
            </Button>
            {!isUploading && (
              <Button danger onClick={handleCancel} size="large" type="primary">
                Cancel
              </Button>
            )}
          </Flex>
        }
        maskClosable={false}
        open={isPhotoUpload}
        onCancel={handleUploadModal}
        title="Upload Photos"
      >
        <Dragger
          accept=".png,.jpg,.jpeg"
          beforeUpload={() => false}
          className="photo-upload"
          listType="picture"
          maxCount={maxUploadLimit - userPhotos.length}
          multiple
          fileList={photoList}
          onChange={handleFileUpload}
          openFileDialogOnClick={
            !!(maxUploadLimit - userPhotos.length - photoList.length)
          }
        >
          <Flex wrap gap={16} justify="center">
            {maxUploadLimit - userPhotos.length - photoList.length ? (
              <>
                <UploadOutlined />
                <span>Upload Images (JPEG / JPG / PNG)</span>
                <span>
                  (Max: {maxUploadLimit - userPhotos.length - photoList.length})
                </span>
              </>
            ) : (
              <span>You've reached your maximum upload limit!</span>
            )}
          </Flex>
        </Dragger>
      </Modal>
    </>
  );
};

export default PhotoUpload;
