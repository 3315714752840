import { Layout } from "antd";
import Header from "../../views/header";
import Loader from "../../components/loader";
import { Outlet } from "react-router-dom";
import "../layout-style.scss";

const { Header: HeaderLayout, Content } = Layout;

const LoginLayout = () => {
  return (
    <Layout className="layout-container">
      <HeaderLayout>
        <Header />
      </HeaderLayout>
      <Content>
        <Loader />
        <Outlet />
      </Content>
    </Layout>
  );
};

export default LoginLayout;
