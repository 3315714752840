import {
  Button,
  Flex,
  Form,
  Input,
  Select,
  TimePicker,
  Typography,
} from "antd";
import CHOICES from "../../constant/register-choices.json";
import NumericInput from "../../custom/numeric-input";

const { Title } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

const {
  DAY_CHOICES,
  RASI_CHOICES,
  STAR_CHOICES,
  PADHAM_CHOICES,
  COUNTRY_LIST,
  STATE_LIST,
  EDUCATION_LIST,
  OCCUPATION_CATEGORY,
  OCCUPATION_LIST,
  MONTHLY_INCOME_CHOICES,
} = CHOICES;

const Step2Form = (props) => {
  const { formData, handleFormData, isLoading, setIsLoading } = props;
  const form = Form.useForm()[0];

  const handlePrevious = () => {
    const fieldsValue = form.getFieldsValue();
    handleFormData(fieldsValue, 0);
  };

  const onFinish = (formValues) => {
    setIsLoading(true);
    handleFormData(formValues, 2);
  };

  return (
    <Form
      labelWrap
      form={form}
      autoComplete="off"
      scrollToFirstError
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      initialValues={formData}
      wrapperCol={{ span: 14 }}
    >
      <Title level={4}>Horoscope Details</Title>
      <>
        <Form.Item
          label="Time of Birth"
          name={["horoscope_details", "time_of_birth"]}
          rules={[
            {
              required: true,
              message: "Please select your time of birth",
            },
          ]}
        >
          <TimePicker
            autoFocus
            use12Hours
            format={"HH:mm"}
            placeholder="HH:MM"
          />
        </Form.Item>
        <Form.Item
          label="Day of Birth"
          name={["horoscope_details", "day_of_birth"]}
          rules={[
            {
              required: true,
              message: "Please select your day of birth",
            },
          ]}
        >
          <Select placeholder="Select your day of birth">
            {DAY_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Place Of Birth"
          name={["horoscope_details", "place_of_birth"]}
          rules={[
            {
              required: true,
              message: "Please enter your place of birth",
            },
          ]}
        >
          <Input placeholder="Enter your place of birth" />
        </Form.Item>
        <Form.Item
          label="Rasi"
          name={["horoscope_details", "rasi"]}
          rules={[
            {
              required: true,
              message: "Please select your rasi",
            },
          ]}
        >
          <Select showSearch placeholder="Select your rasi">
            {RASI_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Nachathiram"
          name={["horoscope_details", "star"]}
          rules={[
            {
              required: true,
              message: "Please select your nachathiram",
            },
          ]}
        >
          <Select showSearch placeholder="Select your nachathiram">
            {STAR_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Padham" name={["horoscope_details", "padham"]}>
          <Select>
            {PADHAM_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Lagnam" name={["horoscope_details", "lagnam"]}>
          <Select showSearch placeholder="Select your lagnam">
            {RASI_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Sevvai"
          name={["horoscope_details", "is_sevvai"]}
          rules={[
            {
              required: true,
              message: "Please select your Sevvai status",
            },
          ]}
        >
          <Select placeholder="Do you have Sevvai?">
            <Option value="yes">Yes</Option>
            <Option value="no">No</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Ragu / Kedhu"
          name={["horoscope_details", "is_ragu_kedhu"]}
          rules={[
            {
              required: true,
              message: "Please select your Ragu / Kedhu status",
            },
          ]}
        >
          <Select placeholder="Do you have Ragu / Kedhu?">
            <Option value="yes">Yes</Option>
            <Option value="no">No</Option>
          </Select>
        </Form.Item>
      </>
      <Title level={4}>Education & Occupation Details</Title>
      <>
        <Form.Item label="College" name={["education_details", "college"]}>
          <TextArea
            maxLength={250}
            count={{
              show: true,
            }}
            rows={2}
            placeholder="Enter you college"
          />
        </Form.Item>
        <Form.Item
          label="Education"
          name={["education_details", "education"]}
          rules={[
            {
              required: true,
              message: "Please select your education",
            },
          ]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Select your education"
          >
            {EDUCATION_LIST.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Education Details"
          name={["education_details", "education_details"]}
        >
          <TextArea
            maxLength={250}
            count={{
              show: true,
            }}
            rows={2}
            placeholder="Enter you education details"
          />
        </Form.Item>
        <Form.Item
          label="Occupation"
          name={["education_details", "occupation"]}
          rules={[
            {
              required: true,
              message: "Please select your occupation",
            },
          ]}
        >
          <Select showSearch placeholder="Select your occupation">
            {OCCUPATION_CATEGORY?.map((group) => (
              <OptGroup key={group} label={group}>
                {OCCUPATION_LIST[group].map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Job Title / Details"
          name={["education_details", "job_title"]}
        >
          <TextArea
            maxLength={350}
            count={{
              show: true,
            }}
            rows={2}
            placeholder="Enter you job title / details"
          />
        </Form.Item>
        <Form.Item
          label="Company Name"
          name={["education_details", "company_name"]}
        >
          <TextArea
            maxLength={150}
            count={{
              show: true,
            }}
            rows={2}
            placeholder="Enter you company name"
          />
        </Form.Item>
        <Form.Item
          label="Currently Employed Country"
          name={["education_details", "currently_employed_country"]}
        >
          <Select showSearch placeholder="Select your employed country">
            {COUNTRY_LIST?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Income (Per Month)"
          name={["education_details", "monthly_income_in_rs"]}
          rules={[
            {
              required: true,
              message: "Please select your monthly income",
            },
          ]}
        >
          <Select showSearch placeholder="Select your monthly income">
            {MONTHLY_INCOME_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
      <Title level={4}>Contact Details</Title>
      <>
        <Form.Item label="Country" name={["contact_details", "country"]}>
          <Select showSearch placeholder="Select your country">
            {COUNTRY_LIST?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="State"
          name={["contact_details", "state"]}
          rules={[
            {
              required: true,
              message: "Please select your state",
            },
          ]}
        >
          <Select showSearch placeholder="Select your state">
            {STATE_LIST?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="City / Town"
          name={["contact_details", "city"]}
          rules={[
            {
              required: true,
              message: "Please enter your city / town",
            },
          ]}
        >
          <Input placeholder="Enter your city / town" />
        </Form.Item>
        <Form.Item
          label="Native"
          name={["contact_details", "native"]}
          rules={[
            {
              required: true,
              message: "Please enter your native place",
            },
          ]}
        >
          <Input placeholder="Enter your native place" />
        </Form.Item>
        <Form.Item
          label="Contact Number 1"
          name={["contact_details", "contact_number_1"]}
          rules={[
            { required: true, message: "Please enter your contact number 1" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid contact number 1",
            },
          ]}
        >
          <NumericInput
            maxLength={10}
            addonBefore="+91"
            placeholder="Enter your contact number 1"
          />
        </Form.Item>
        <Form.Item
          label="Contact Number 2"
          name={["contact_details", "contact_number_2"]}
          rules={[
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid contact number 2",
            },
          ]}
        >
          <NumericInput
            maxLength={10}
            addonBefore="+91"
            placeholder="Enter your contact number 2"
          />
        </Form.Item>
      </>
      <Flex justify="center" gap="small">
        <Button onClick={handlePrevious}>Previous</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Continue
        </Button>
      </Flex>
    </Form>
  );
};

export default Step2Form;
