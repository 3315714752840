import { useEffect, useState } from "react";
import {
  Table,
  Form,
  Col,
  Input,
  Button,
  Row,
  Select,
  notification,
  Pagination,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loaderReducer";
import "./style.scss";

const { Option } = Select;

const getRefreshToken = () => localStorage.getItem("refreshToken");

const ReportedIssue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();

  const [form1] = Form.useForm();
  const [deleteData, setDeleteData] = useState([]);
  const [pagination, setPagination] = useState({});

  const reported_issue_status = ["Reported", "Resolved"];
  const initialValues = { report_status: ["Reported"], profile_id: "" };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Profile Id",
      dataIndex: "reporter_profile_id",
    },
    {
      title: "Profile Name",
      dataIndex: "reporter_profile_name",
    },
    {
      title: "Report",
      dataIndex: "reported_issue",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Report Status",
      dataIndex: "is_report_resolved",
    },
    {
      title: "Action",
      render: (data) => (
        <Button
          type="primary"
          onClick={() => {
            handleViewProfile(data?.reporter_profile_id);
          }}
        >
          View Profile
        </Button>
      ),
    },
  ];

  const fetchReportedIssueData = (payload) => {
    dispatch(startLoading());
    const url = "/get_reported_issues";

    authAxios
      .post(url, payload)
      .then((response) => {
        setDeleteData(response.data.data);
        setPagination(response.data.pagination_data);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const onFinishSearch = (formValues) => {
    const payload = {
      report_status: formValues.report_status,
      page_number: 1,
      records_per_page: 10,
    };
    fetchReportedIssueData(payload);
  };

  const handleSessionExpired = () => {
    alert("Your session has expired. Please log in again.");
    navigate("/login");
  };

  const handleViewProfile = (profile_id) => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      window.open(`/search/${profile_id}`, "_blank");
    } else {
      handleSessionExpired();
    }
  };

  const handlePageChange = (pageNo) => {
    const { report_status } = form1.getFieldsValue();
    const payload = {
      report_status,
      page_number: pageNo,
      records_per_page: 10,
    };
    fetchReportedIssueData(payload);
  };

  useEffect(() => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const payload = {
        report_status: ["Reported"],
        page_number: 1,
        records_per_page: 10,
      };
      fetchReportedIssueData(payload);
    } else {
      handleSessionExpired();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography.Title level={3} className="page-title">
        Reported Issues
      </Typography.Title>
      <Form
        autoComplete="off"
        className="reported-issue-search"
        initialValues={initialValues}
        form={form1}
        onFinish={onFinishSearch}
      >
        <Row align="middle" gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item
              label="Reported Issue Status"
              name="report_status"
              rules={[
                {
                  required: true,
                  message: "Please select profile  status",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select profile verification status"
              >
                {reported_issue_status.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item name="profile_id" label="Search Profile ID">
              <Input placeholder="Enter Profile ID" />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        className="reported-issue-table"
        columns={columns}
        dataSource={deleteData}
        pagination={false}
        bordered
        scroll={{
          x: 920,
        }}
        rowKey="profile_username"
      />
      <br />
      <Pagination
        className="reported-issue-pagination"
        total={pagination.total_objects}
        current={pagination.requested_page_number}
        onChange={handlePageChange}
      />
    </>
  );
};

export default ReportedIssue;
