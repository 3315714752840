import React, { useState } from "react";
import { Spin, Steps, notification } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { encryptData, UseAxiosUpload } from "../../utils";
import Step1Form from "./step-1-form";
import Step2Form from "./step-2-form";
import Step3Form from "./step-3-form";
import "./style.scss";

const defaultFormValues = {
  basic_details: {},
  horoscope_details: {
    padham: 0,
    lagnam: "",
  },
  education_details: {
    college: "",
    education_details: "",
    job_title: "",
    company_name: "",
    currently_employed_country: "India",
  },
  contact_details: {
    country: "India",
    contact_number_2: "",
  },
  physical_details: {
    profile_media_files: [],
    blood_group: "",
  },
  family_details: {
    father_occupation: "",
    mother_occupation: "",
    no_of_sisters: 0,
    no_sisters_married: 0,
    no_of_brothers: 0,
    no_brothers_married: 0,
    family_income_in_rs: "",
    about_family: "",
    total_asset_value: "",
    asset_details: "",
  },
  partner_preference: {
    age_range: [18, 45],
    skin_tone: "",
    is_horoscope_match: "",
    is_sevvai: "",
    is_ragu_kethu: "",
    is_brother: "",
    is_sisters: "",
    food_preference: "",
    description: "",
    education: [],
    occupation: "",
  },
};

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultFormValues);
  const [registerStep, setRegisterStep] = useState(0);
  const [verifiedProfile, setVerifiedProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistration = (payload) => {
    const url = "user_registration";
    UseAxiosUpload(false)
      .post(url, payload)
      .then((response) => {
        setFormData([]);
        notification.success({ message: response.data.message });
        navigate("/login");
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(false));
  };

  const handleDataUpdate = (data) => {
    const photos = [...data.physical_details.profile_media_files];
    const clonedData = JSON.parse(JSON.stringify({ ...formData, ...data }));
    const {
      basic_details,
      horoscope_details,
      contact_details,
      physical_details,
    } = clonedData;
    const formDataObject = new FormData();

    const formatDate = (date, format) => dayjs(date).format(format);

    // Update basic_details
    Object.assign(basic_details, {
      password: encryptData(basic_details.password),
      confirm_password: encryptData(basic_details.confirm_password),
      dob: formatDate(basic_details.dob, "YYYY/MM/DD"),
      country_code: "+91",
    });

    // Handle profile media files
    if (photos.length) {
      photos.forEach((file) => {
        formDataObject.append("profile_media_files", file.originFileObj);
      });
      delete physical_details.profile_media_files;
    }

    // Update horoscope_details
    horoscope_details.time_of_birth = formatDate(
      horoscope_details.time_of_birth,
      "HH:mm:00"
    );

    // Update contact_details
    contact_details.contact_number_2 = contact_details.contact_number_2 ?? "";

    // Append final payload
    formDataObject.append("json_payload_str", JSON.stringify(clonedData));
    handleRegistration(formDataObject);
  };

  const handleFormData = (data, step) => {
    setFormData({
      ...formData,
      ...data,
    });
    if (step === 3) {
      handleDataUpdate(data);
    } else {
      if (step === 0) {
        setVerifiedProfile(formData.basic_details.email_id);
      }
      setIsLoading(false);
      setRegisterStep(step);
    }
  };

  const registerItems = [
    {
      title: "Basic Details",
    },
    {
      title: (
        <>
          Horoscope & Contact Details <br />
          Education & Occupation Details
        </>
      ),
    },
    {
      title: (
        <>
          Physical & Family Details
          <br />
          Partner Preference
        </>
      ),
    },
  ];

  return (
    <div className="register-container">
      <Spin spinning={isLoading} size="large" tip="Loading...">
        <Steps
          size="small"
          type="navigation"
          current={registerStep}
          items={registerItems}
        />
        <br />
        {registerStep === 0 && (
          <Step1Form
            formData={formData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleFormData={handleFormData}
            verifiedProfile={verifiedProfile}
          />
        )}
        {registerStep === 1 && (
          <Step2Form
            formData={formData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleFormData={handleFormData}
          />
        )}
        {registerStep === 2 && (
          <Step3Form
            formData={formData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleFormData={handleFormData}
          />
        )}
      </Spin>
    </div>
  );
};
export default Register;
