import axios from "axios";
import { BASE_URL } from "../config";

const UseAxiosUpload = (isToken = true) => {
  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    if (isToken && !config.headers.Authorization) {
      const accessToken = window.localStorage.accessToken;
      config.headers.Authorization = `jwt ${accessToken}`;
    }
    return config;
  });

  return axiosInstance;
};

export default UseAxiosUpload;
