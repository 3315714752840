import CryptoJS from "crypto-js";

const keySize = 16
//Key shared by service [SECRET_KEY,INITIALIZATION_VECTOR, MODE]
const key = CryptoJS.enc.Utf8.parse("b6cf97514323391f96bc30e923ec73ab");
const iv = CryptoJS.enc.Utf8.parse("6hqNXL2day44YLdb");
const mode = CryptoJS.mode.CBC;

//Encrypt the given sting
export const encryptData = (encryptdata) => {
  try {
    let data = encryptdata ? JSON.stringify(encryptdata) : '';
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      keySize: keySize,
      iv: iv,
      mode: mode,
    });
    return encrypted + "";
  } catch (err) {
    console.log('encryptData', err);
  }
};

//Decrypt the given sting
export const decryptData = (decryptdata) => {
  try {
    let data = decryptdata ? decryptdata : '';
    const plainText = CryptoJS.AES.decrypt(data, key, {
      keySize: keySize,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7
    });
    return JSON.parse(plainText.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    console.log('decryptData', err);
  }
};
