import React, { useMemo } from "react";
import { Button, ConfigProvider } from "antd";
import "./style.scss";

const CustomButton = ({ success, warning, children, ...rest }) => {
  const customColor = useMemo(() => {
    if (success) return "#05870E";
    if (warning) return "#FAAD14";
    return null;
  }, [success, warning]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: customColor,
        },
      }}
    >
      <Button className="custom-button" {...rest}>
        {children}
      </Button>
    </ConfigProvider>
  );
};

export default CustomButton;
