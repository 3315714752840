import React from "react";
import { Flex, Image } from "antd";
import instagramLogo from "../../image/instagram-logo.svg";
import youtubeLogo from "../../image/youtube-logo.svg";
import facebookLogo from "../../image/facebook-logo.svg";
import "./style.scss";

const Advertisement = () => {
  const sectionList = [1, 2, 3, 4];
  const socialMediaLinks = [
    {
      href: "https://www.instagram.com/NKVG_Matrimony",
      src: instagramLogo,
      label: "NKVG_Matrimony",
      className: "insta",
    },
    {
      href: "https://www.youtube.com/@NKVG_Matrimony",
      src: youtubeLogo,
      label: "NKVG_Matrimony",
      className: "youtube",
    },
    {
      href: "https://www.facebook.com/NKVGMatrimony",
      src: facebookLogo,
      label: "NKVG_Matrimony",
      className: "fb",
    },
  ];

  const SocialLink = ({ href, src, label, className }) => (
    <li>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        <Image preview={false} src={src} height={32} />
        {label}
      </a>
    </li>
  );

  return (
    <Flex className="ad-container">
      {sectionList.map((list) => (
        <ul key={list}>
          <li>Follow us on social media for regular updates and more!</li>
          {socialMediaLinks.map((link) => (
            <SocialLink key={link.href} {...link} />
          ))}
        </ul>
      ))}
    </Flex>
  );
};

export default Advertisement;
