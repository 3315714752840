import { Col, Flex, Row } from "antd";
import { Link } from "react-router-dom";
import "./style.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Row
      align="middle"
      className="footer-container"
      justify={{ lg: "space-between", md: "center" }}
      gutter={[0, 8]}
      wrap
    >
      <Flex wrap gap={8} justify="center">
        <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy |
        </Link>
        <Link
          to="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions |
        </Link>
        <Link to="/about" target="_blank" rel="noopener noreferrer">
          About Us
        </Link>
      </Flex>
      <Col align="center">
        ©{currentYear} NKVG Matrimony Services (OPC) Pvt Ltd. All
        rights reserved.
      </Col>
    </Row>
  );
};

export default Footer;
