import React from "react";
import { Row, Col, Image } from "antd";
import ContactImage from "../../image/contactus.jpg";
import { HomeOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import Instagramimg from "../../image/insta.png";
import Facebookimg from "../../image/fb.png";
import Youtubeimg from "../../image/yt.png";
import "../contact/contact.scss";

const Contact = () => {
  const navigateSocial = (type) => {
    if (type === "youtube") {
      window.open("https://www.youtube.com/@NKVG_Matrimony");
    } else if (type === "instagram") {
      window.open("https://www.instagram.com/nkvg_matrimony/");
    } else {
      window.open(
        "https://www.facebook.com/profile.php?id=61560041802298&mibextid=ZbWKwL"
      );
    }
  };

  return (
    <>
      <Row align="middle" justify="space-evenly" className="">
        <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
          <div className="text-center">
            <Image
              src={ContactImage}
              preview={false}
              alt="login-img"
              className="w-75"
            />
          </div>
          <div className="social-media text-center positionsocial mt-n5"></div>
        </Col>
        <Col
          xs={24}
          sm={20}
          md={18}
          lg={10}
          xl={10}
          xxl={10}
          className="form-container"
        >
          <div className="contact-info mt-5">
            <h3 className="fw my-5 text-center">Contact Us</h3>

            <div className="contact-info-item">
              <div className="contact-info-icon">
                <HomeOutlined style={{ fontSize: "55px" }} />
              </div>

              <div className="contact-info-content">
                <h4>Address</h4>
                <p>
                  Currently we are operating only online, soon available in
                  offline.
                </p>
              </div>
            </div>

            <div className="contact-info-item">
              <div className="contact-info-icon">
                <PhoneOutlined style={{ fontSize: "55px" }} />{" "}
              </div>
              <div className="contact-info-content">
                <h4>Phone</h4>
                <p>+91 7010493235</p>
              </div>
            </div>

            <div className="contact-info-item">
              <div className="contact-info-icon">
                <MailOutlined style={{ fontSize: "55px" }} />{" "}
              </div>
              <div className="contact-info-content">
                <h4>Email</h4>
                <p>nkvgmatrimony@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="contact-info mt-5">
            <h3 className="fw my-5 text-center">Social Media</h3>
            <div
              className="contact-info-item"
              onClick={() => navigateSocial("instagram")}
            >
              <div className="contact-info-icon">
                <img src={Instagramimg} className="w-100" alt="" />
              </div>
              <div className="contact-info-content">
                <h4>Instagram</h4>
                <p>NKVG_Matrimony</p>
              </div>
            </div>

            <div
              className="contact-info-item"
              onClick={() => navigateSocial("youtube")}
            >
              <div className="contact-info-icon">
                <img src={Youtubeimg} className="w-100" alt="" />
              </div>
              <div className="contact-info-content">
                <h4>Youtube</h4>
                <p>NKVG_Matrimony</p>
              </div>
            </div>

            <div
              className="contact-info-item"
              onClick={() => navigateSocial("facebook")}
            >
              <div className="contact-info-icon">
                <img src={Facebookimg} className="w-100" alt="" />
              </div>
              <div className="contact-info-content">
                <h4>Facebook</h4>
                <p>NKVG_Matrimony</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Contact;
