import { Button, Flex, Form, Input, Radio } from "antd";
import React, { useState } from "react";

const DeleteAccount = (properties) => {
  const { handleActionCancel, handleConfirm } = properties;
  const [form] = Form.useForm();

  const [isReason, setIsReason] = useState(false);

  const deleteOptions = [
    {
      delete_key: "found-match",
      delete_reason: "I found my match on this platform",
    },
    {
      delete_key: "no-longer-looking",
      delete_reason: "I am no longer looking for a match",
    },
    {
      delete_key: "personal-reasons",
      delete_reason: "Personal reasons for deleting my profile",
    },
    {
      delete_key: "privacy-concerns",
      delete_reason: "I have privacy or security concerns",
    },
    {
      delete_key: "unsatisfactory-experience",
      delete_reason: "I am dissatisfied with the services provided",
    },
    {
      delete_key: "too-many-notifications",
      delete_reason: "I am receiving too many notifications",
    },
    {
      delete_key: "technical-issues",
      delete_reason: "I experienced technical issues with the platform",
    },
    {
      delete_key: "found-elsewhere",
      delete_reason: "I found a match on another platform",
    },
    {
      delete_key: "not-enough-matches",
      delete_reason: "I am not finding enough compatible matches",
    },
    {
      delete_key: "taking-break",
      delete_reason: "I prefer to take a break and may return later",
    },
    { delete_key: "others", delete_reason: "Others (Please specify)" },
  ];

  const onFinish = (formValues) => {
    const { reasonKey, description } = formValues;
    const { delete_key, delete_reason } = deleteOptions[reasonKey];
    const deletePayload = {
      delete_key,
      delete_reason: isReason ? description : delete_reason,
    };
    handleConfirm(3, deletePayload);
  };

  const handleValuesChange = (_, formData) => {
    const isReasonKey = formData.reasonKey === deleteOptions.length - 1;
    if (!isReasonKey) {
      form.resetFields(["description"]);
    }
    setIsReason(isReasonKey);
  };

  return (
    <Form
      form={form}
      autoComplete="off"
      className="change-password-form"
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
    >
      <Form.Item
        label="Select a reason"
        name="reasonKey"
        rules={[
          {
            required: true,
            message: "Please choose a reason",
          },
        ]}
      >
        <Radio.Group>
          <Flex gap={8} vertical>
            {deleteOptions.map((option, index) => (
              <Radio key={option.delete_key} value={index}>
                {option.delete_reason}
              </Radio>
            ))}
          </Flex>
        </Radio.Group>
      </Form.Item>
      {isReason && (
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter a reason",
            },
            {
              min: 25,
              message: "Please enter atleast 20 characters",
            },
          ]}
        >
          <Input.TextArea placeholder="Enter a reason" />
        </Form.Item>
      )}
      <Flex gap="small" justify="center">
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
        <Button danger type="primary" onClick={handleActionCancel}>
          Cancel
        </Button>
      </Flex>
    </Form>
  );
};

export default DeleteAccount;
