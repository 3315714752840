import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import dayjs from "dayjs";
import { MailTwoTone } from "@ant-design/icons";
import { encryptData, UseAxios } from "../../utils";
import CHOICES from "../../constant/register-choices.json";
import NumericInput from "../../custom/numeric-input";

const { Group } = Radio;
const { Option } = Select;
const { Password } = Input;
const { Title } = Typography;

const {
  GENDER_CHOICES,
  KULAM_CHOICES,
  ACCOUNT_CREATED_BY_CHOICES,
  MARITAL_STATUS_CHOICES,
} = CHOICES;

const Step1Form = (props) => {
  const { formData, handleFormData, isLoading, setIsLoading, verifiedProfile } =
    props;

  const form = Form.useForm()[0];
  const [form1Data, setForm1Data] = useState({});
  const [otp, setOtp] = useState("");

  const confirmPasswordValidator = (_, value) => {
    const passwordValue = form.getFieldValue(["basic_details", "password"]);
    if (value && value !== passwordValue) {
      return Promise.reject(
        new Error("Password and Confirm Password must be match")
      );
    }
    return Promise.resolve();
  };

  const enabledFor18YearsOld = (current) => {
    const eighteenYearsAgo = dayjs().subtract(18, "year");
    return current && dayjs(current).isAfter(eighteenYearsAgo, "day");
  };

  const handleUserValidation = (formValues) => {
    setIsLoading(true);
    const { email_id, password } = formValues.basic_details;
    const encryptedPassword = encryptData(password);
    const url = "validate_email_and_password";
    const payload = {
      email_id,
      password: encryptedPassword,
    };

    UseAxios.post(url, payload)
      .then(() => {
        handleSendEmailCode(formValues);
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({ message: error.response.data.message });
      });
  };

  const handleSendEmailCode = (data) => {
    const { email_id, first_name, last_name, number } = data.basic_details;
    const url = "request_otp_for_email_verification";
    const emailPayload = {
      email_id,
      user_full_name: first_name + last_name,
      mobile_number: number,
    };
    UseAxios.post(url, emailPayload)
      .then(() => {
        setForm1Data(data);
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      })
      .finally(() => setIsLoading(false));
  };

  const handleEmailVerifyCancel = () => {
    setOtp("");
    setForm1Data({});
  };

  const handleVerifyEmailCode = () => {
    setIsLoading(true);
    const { email_id } = form1Data.basic_details;
    const url = "verify_email_with_otp";
    const otpPayload = {
      email_id,
      otp,
    };
    UseAxios.post(url, otpPayload)
      .then((response) => {
        handleFormData(form1Data, 1);
        handleEmailVerifyCancel();
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({ message: error.response.data.message });
      });
  };

  const onFinish = (formValues) => {
    const { email_id } = formValues.basic_details;
    if (verifiedProfile === email_id) {
      handleFormData(form1Data, 1);
    } else {
      handleUserValidation(formValues);
    }
  };

  return (
    <>
      <Form
        labelWrap
        form={form}
        autoComplete="off"
        scrollToFirstError
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        initialValues={formData}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          label="First Name"
          name={["basic_details", "first_name"]}
          rules={[{ required: true, message: "Please enter your first name" }]}
        >
          <Input autoFocus placeholder="Enter your first name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={["basic_details", "last_name"]}
          rules={[{ required: true, message: "Please enter your last name" }]}
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>
        <Form.Item
          validateFirst
          label="Email"
          name={["basic_details", "email_id"]}
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              message: "Please enter a valid email address.",
            },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name={["basic_details", "password"]}
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
            {
              min: 8,
              message: "Password must be at least 8 characters long",
            },
            {
              max: 15,
              message: "Password must be less than 15 characters",
            },
            {
              pattern: /[0-9]/,
              message: "Password should have at least one number",
            },
            {
              pattern: /[a-z]/,
              message: "Password should have at least one lowercase letter",
            },
            {
              pattern: /[A-Z]/,
              message: "Password should have at least one uppercase letter",
            },
            {
              pattern: /[!@#$%^&*]/,
              message:
                "Password should have at least one symbol ! @ # $ % ^ & *",
            },
            {
              pattern: /^[a-zA-Z0-9!@#$%^&*]+$/,
              message:
                "Use only these symbols ! @ # $ % ^ & * in your password",
            },
            {
              pattern: /^\S+$/,
              message: "Passwords cannot contain spaces",
            },
          ]}
        >
          <Password placeholder="Enter your password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name={["basic_details", "confirm_password"]}
          dependencies={["basic_details", "password"]}
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
            {
              validator: confirmPasswordValidator,
            },
          ]}
        >
          <Password placeholder="Enter confirm password" />
        </Form.Item>
        <Form.Item
          label="Gender"
          name={["basic_details", "gender"]}
          rules={[
            {
              required: true,
              message: "Please select your gender",
            },
          ]}
        >
          <Group>
            {GENDER_CHOICES?.map(({ value, label }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </Group>
        </Form.Item>
        <Form.Item
          label="Date of Birth"
          name={["basic_details", "dob"]}
          rules={[
            {
              required: true,
              message: "Please enter your date of birth",
            },
          ]}
        >
          <DatePicker
            disabledDate={enabledFor18YearsOld}
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item
          label="Mobile Number"
          name={["basic_details", "number"]}
          rules={[
            { required: true, message: "Please enter your mobile number" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid mobile number",
            },
          ]}
        >
          <NumericInput
            maxLength={10}
            addonBefore="+91"
            placeholder="Enter your mobile number"
          />
        </Form.Item>
        <Form.Item
          label="Caste"
          name={["basic_details", "caste"]}
          rules={[
            {
              required: true,
              message: "Please select your caste",
            },
          ]}
        >
          <Select placeholder="Select your caste">
            <Option value="Kongu Vellala Gounder">Kongu Vellala Gounder</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Sub Caste / Other Name"
          name={["basic_details", "sub_caste"]}
          rules={[
            {
              required: true,
              message: "Please select your sub caste",
            },
          ]}
        >
          <Select placeholder="Select your sub caste">
            <Option value="Valar Kongu Vellala Gounder">
              Mudamandaar Gounder / முடமாண்டார் கவுண்டர்
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Kulam"
          name={["basic_details", "kulam"]}
          rules={[
            {
              required: true,
              message: "Please select your kulam",
            },
          ]}
        >
          <Select showSearch placeholder="Select your kulam">
            {KULAM_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Kovil"
          name={["basic_details", "kovil"]}
          rules={[
            {
              required: true,
              message: "Please enter your kovil",
            },
          ]}
        >
          <Input placeholder="Enter your kovil" />
        </Form.Item>
        <Form.Item
          label="Kovil Location"
          name={["basic_details", "kovil_location"]}
          rules={[
            {
              required: true,
              message: "Please enter your kovil location",
            },
          ]}
        >
          <Input placeholder="Enter your kovil location" />
        </Form.Item>
        <Form.Item
          label="Account Created By"
          name={["basic_details", "account_created_by"]}
          rules={[
            {
              required: true,
              message: "Please select your account creator",
            },
          ]}
        >
          <Select placeholder="Select account creator">
            {ACCOUNT_CREATED_BY_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Marital Status"
          name={["basic_details", "marital_status"]}
          rules={[
            { required: true, message: "Please select your marital status" },
          ]}
        >
          <Select>
            {MARITAL_STATUS_CHOICES?.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="pp-eula-checkbox"
          name={["basic_details", "is_pp_eula_accepted"]}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Check the Privacy Policy & Terms and Conditions to proceed further."
                      )
                    ),
            },
          ]}
          valuePropName="checked"
          wrapperCol={{ span: 24 }}
        >
          <Checkbox>
            I agree to the{" "}
            <a href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/terms-and-conditions" target="_blank">
              Terms and Conditions
            </a>{" "}
            NKVG of Matrimony.
          </Checkbox>
        </Form.Item>
        <Flex justify="center" gap="small">
          <Button disabled>Previous</Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Continue
          </Button>
        </Flex>
      </Form>
      <Modal
        centered
        closeIcon={false}
        maskClosable={false}
        className="email-verify-modal"
        open={"basic_details" in form1Data}
        footer={[
          <Row key="email-verify-footer" justify="center" gutter={[8, 0]}>
            <Col>
              <Button
                type="primary"
                loading={isLoading}
                disabled={otp.length !== 6}
                onClick={handleVerifyEmailCode}
              >
                Send
              </Button>
            </Col>
            <Col>
              <Button danger type="primary" onClick={handleEmailVerifyCancel}>
                Cancel
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col span={24} align="center">
            <MailTwoTone />
          </Col>
          <Col span={24}>
            <Title level={4} align="center">
              Verify your email address
            </Title>
          </Col>
          <Col span={24} align="center">
            A verification email has been sent to your inbox. Please check your
            email and enter the verification code below.
          </Col>
          <Col align="center">
            <Input
              value={otp}
              maxLength={6}
              placeholder="Enter the code"
              onChange={(event) => setOtp(event.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Step1Form;
