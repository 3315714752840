import React, { useState } from "react";
import { Card, notification } from "antd";
import { Form, DatePicker, Select, Button } from "antd";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loaderReducer";
import styles from "./style.module.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AdminDashboard = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();
  const [tableData, settableData] = useState([]);

  const onFinish = (values) => {
    console.log("Form Values:", values);
    let framePayload = {
      from_date:
        values?.dateRange && values?.dateRange[0] ? values?.dateRange[0] : "",
      to_date:
        values?.dateRange && values?.dateRange[1] ? values?.dateRange[1] : "",
      membership_type: values?.type ? values?.type : "",
      gender: values?.gender ? values?.gender : "",
    };

    fetchDahBoardData(framePayload);
  };

  const onClear = () => {
    form.resetFields();
  };

  const fetchDahBoardData = (payload) => {
    dispatch(startLoading());
    const url = "/admin_dashboard";

    authAxios
      .post(url, payload)
      .then((response) => {
        settableData(response.data.data);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  return (
    <>
      <Card bordered={false} style={{ width: "100%" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{
            maxWidth: "80%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {/* Date Range */}
          <Form.Item
            label="Date Range"
            name="dateRange"
            // rules={[
            //   { required: true, message: "Please select the date range" },
            // ]}
          >
            <RangePicker />
          </Form.Item>

          {/* Type */}
          <Form.Item
            label="MemberShip Type"
            name="type"
            // rules={[{ required: true, message: "Please select the type" }]}
            style={{ width: "15%" }}
          >
            <Select placeholder="Select type">
              <Option value="BASIC">BASIC</Option>
              <Option value="GOLD">GOLD</Option>
              <Option value="DIAMOND">DIAMOND</Option>
              <Option value="PLATINUM">PLATINUM</Option>
            </Select>
          </Form.Item>

          {/* Gender */}
          <Form.Item
            label="Gender"
            name="gender"
            //   rules={[{ required: true, message: "Please select gender" }]}
            style={{ width: "15%" }}
          >
            <Select placeholder="Select gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Transgender</Option>
            </Select>
          </Form.Item>

          {/* Submit and Clear Buttons */}
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
            >
              Submit
            </Button>
            <Button htmlType="button" onClick={onClear}>
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <div className="responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">GENDER</th>
              <th scope="col">BASIC</th>
              <th scope="col">GOLD</th>
              <th scope="col">DIAMOND</th>
              <th scope="col">PLATINUM</th>
              <th scope="col">PROFILES</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Male</th>
              <td>
                {tableData?.main_table_data?.male_basic_membership_count
                  ? tableData?.main_table_data?.male_basic_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.male_gold_membership_count
                  ? tableData?.main_table_data?.male_gold_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.male_diamond_membership_count
                  ? tableData?.main_table_data?.male_diamond_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.male_platinum_membership_count
                  ? tableData?.main_table_data?.male_platinum_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.male_profile_count
                  ? tableData?.main_table_data?.male_profile_count
                  : "-"}
              </td>
            </tr>
            <tr>
              <th scope="row">Female</th>
              <td>
                {tableData?.main_table_data?.female_basic_membership_count
                  ? tableData?.main_table_data?.female_basic_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.female_gold_membership_count
                  ? tableData?.main_table_data?.female_gold_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.female_diamond_membership_count
                  ? tableData?.main_table_data?.female_diamond_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.female_platinum_membership_count
                  ? tableData?.main_table_data?.female_platinum_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.female_profile_count
                  ? tableData?.main_table_data?.female_profile_count
                  : "-"}
              </td>
            </tr>
            <tr>
              <th scope="row">Transgender</th>
              <td>
                {tableData?.main_table_data?.transgender_basic_membership_count
                  ? tableData?.main_table_data
                      ?.transgender_basic_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.transgender_gold_membership_count
                  ? tableData?.main_table_data
                      ?.transgender_gold_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data
                  ?.transgender_diamond_membership_count
                  ? tableData?.main_table_data
                      ?.transgender_diamond_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data
                  ?.transgender_platinum_membership_count
                  ? tableData?.main_table_data
                      ?.transgender_platinum_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.transgender_profile_count
                  ? tableData?.main_table_data?.transgender_profile_count
                  : "-"}
              </td>
            </tr>
            <tr>
              <th scope="row">Total</th>
              <td>
                {tableData?.main_table_data?.basic_membership_count
                  ? tableData?.main_table_data?.basic_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.gold_membership_count
                  ? tableData?.main_table_data?.gold_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.diamond_membership_count
                  ? tableData?.main_table_data?.diamond_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.platinum_membership_count
                  ? tableData?.main_table_data?.platinum_membership_count
                  : "-"}
              </td>
              <td>
                {tableData?.main_table_data?.total_profile_count
                  ? tableData?.main_table_data?.total_profile_count
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles.CardView}>
        <Card
          title="Pending Profile Reports"
          bordered={true}
          style={{ width: "30%" }}
        >
          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbar
              value={
                tableData?.pending_profile_reports
                  ? tableData?.pending_profile_reports
                  : 0
              }
              text={`${
                tableData?.pending_profile_reports
                  ? tableData?.pending_profile_reports
                  : 0
              }`}
              styles={buildStyles({
                pathColor: "#4caf50", // Set the progress bar color
                // Background circle color
                trailColor: "#d6d6d6", // Set the background circle color
                // Text color inside the circle
                textColor: "#4caf50", // Set the text color
                // Text size
                textSize: "16px", // Adjust as needed
              })}
            />
          </div>
        </Card>
        <Card
          title="Pending Issue Reports"
          bordered={true}
          style={{ width: "30%" }}
        >
          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbar
              value={
                tableData?.pending_issue_reports
                  ? tableData?.pending_issue_reports
                  : 0
              }
              text={`${
                tableData?.pending_issue_reports
                  ? tableData?.pending_issue_reports
                  : 0
              }`}
              styles={buildStyles({
                pathColor: "#e73523", // Set the progress bar color
                // Background circle color
                trailColor: "#d6d6d6", // Set the background circle color
                // Text color inside the circle
                textColor: "#e73523", // Set the text color
                // Text size
                textSize: "16px", // Adjust as needed
              })}
            />
          </div>
        </Card>
        <Card
          title="Total Login Count"
          bordered={true}
          style={{ width: "30%" }}
        >
          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbar
              value={
                tableData?.today_login_count ? tableData?.today_login_count : 0
              }
              text={`${
                tableData?.today_login_count ? tableData?.today_login_count : 0
              }`}
              styles={buildStyles({
                pathColor: "#4caf50", // Set the progress bar color
                // Background circle color
                trailColor: "#d6d6d6", // Set the background circle color
                // Text color inside the circle
                textColor: "#3e98c7", // Set the text color
                // Text size
                textSize: "16px", // Adjust as needed
              })}
            />
          </div>
        </Card>
      </div>

      <Card
        title={
          tableData.length > 0
            ? "Total Profile Created - " +
              tableData?.total_profile_table_data?.total_profile_count
            : "Total Profile Created - 0"
        }
        bordered={true}
        style={{ width: "100%" }}
      >
        <div className={styles.CardView}>
          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbarWithChildren
              value={
                tableData?.total_profile_table_data?.male_profile_count
                  ? tableData?.total_profile_table_data?.male_profile_count
                  : 0
              }
              styles={buildStyles({
                pathColor: "#2356e7", // Set the progress bar color
                // Background circle color
                trailColor: "#d6d6d6", // Set the background circle color
                // Text color inside the circle
                textColor: "#2356e7", // Set the text color
                // Text size
                textSize: "16px", // Adjust as needed
              })}
            >
              {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
              {/* <img
                style={{ width: 40, marginTop: -5 }}
                src="../image/female-profile-placeholder.jpg"
                alt="doge"
              /> */}
              <div style={{ fontSize: 16, marginTop: -5 }}>
                <strong>
                  {tableData?.total_profile_table_data?.male_profile_count
                    ? tableData?.total_profile_table_data?.male_profile_count
                    : 0}
                </strong>{" "}
                <br></br>
                Male
              </div>
            </CircularProgressbarWithChildren>
          </div>

          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbarWithChildren
              value={
                tableData?.total_profile_table_data?.female_profile_count
                  ? tableData?.total_profile_table_data?.female_profile_count
                  : 0
              }
              styles={buildStyles({
                pathColor: "#db23e7", // Set the progress bar color
                // Background circle color
                trailColor: "#d6d6d6", // Set the background circle color
                // Text color inside the circle
                textColor: "#db23e7", // Set the text color
                // Text size
                textSize: "16px", // Adjust as needed
              })}
            >
              {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
              {/* <img
                style={{ width: 40, marginTop: -5 }}
                src="../image/female-profile-placeholder.jpg"
                alt="doge"
              /> */}
              <div style={{ fontSize: 16, marginTop: -5 }}>
                <strong>
                  {tableData?.total_profile_table_data?.female_profile_count
                    ? tableData?.total_profile_table_data?.female_profile_count
                    : 0}
                </strong>{" "}
                Female
              </div>
            </CircularProgressbarWithChildren>
          </div>

          <div style={{ width: 200, height: 200 }}>
            <CircularProgressbarWithChildren
              value={
                tableData?.total_profile_table_data?.transgender_profile_count
                  ? tableData?.total_profile_table_data
                      ?.transgender_profile_count
                  : 0
              }
            >
              {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
              {/* <img
                style={{ width: 40, marginTop: -5 }}
                src="https://i.imgur.com/b9NyUGm.png"
                alt="doge"
              /> */}
              <div style={{ fontSize: 16, marginTop: -5 }}>
                <strong>
                  {tableData?.total_profile_table_data
                    ?.transgender_profile_count
                    ? tableData?.total_profile_table_data
                        ?.transgender_profile_count
                    : 0}
                </strong>{" "}
                Transgender
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AdminDashboard;
