import { message, Upload } from "antd";

export const handleFileValidation = ({ type, size }) => {
  const isValidType = ["image/jpeg", "image/jpg", "image/png"].includes(type);

  if (!isValidType) {
    message.error("You can only upload JPEG/JPG/PNG files");
    return Upload.LIST_IGNORE;
  }

  if (size >= 5 * 1024 * 1024) {
    message.error("Photo size must be smaller than 5MB");
    return Upload.LIST_IGNORE;
  }

  return false;
};
