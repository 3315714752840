import { Typography } from "antd";
import "./style.scss";

const TermsAndConditions = () => {
  return (
    <div className="tc-container">
      <Typography.Title level={2}>
        Terms and Conditions of Namma Kongu Vellalar Matrimony
      </Typography.Title>
      <p>
        <strong>Last Updated: 07/07/2024</strong>
      </p>
      <p>
        This End User License Agreement ("EULA") is a legal agreement between
        you ("User" or "You") and Namma Kongu Vellalar Matrimony ("Company,"
        "We," "Us," or "Our") governing your use of our matrimonial services
        ("Services"). By accessing or using the Services, you agree to be bound
        by the terms and conditions of this EULA.
      </p>
      <strong>1. Acceptance of Terms</strong>
      <p>
        By creating an account, accessing, or using our Services, you agree to
        comply with and be bound by this EULA. If you do not agree with the
        terms of this EULA, you must not use our Services.
      </p>
      <strong>2. Eligibility</strong>
      <p>
        You must be at least 18 years old and legally competent to enter into a
        binding contract under applicable laws. By using the Services, you
        represent and warrant that you meet these eligibility requirements.
      </p>
      <strong>3. Account Registration</strong>
      <p>
        3.1 To use our Services, you must create an account and provide
        accurate, complete, and up-to-date information as requested during the
        registration process.
        <br />
        3.2 You are responsible for maintaining the confidentiality of your
        account credentials and for all activities that occur under your
        account. You agree to notify us immediately of any unauthorized use of
        your account.
      </p>
      <strong>4. Membership Types and Features</strong>
      <p>
        4.1 We offer various membership types with different features and
        benefits. Detailed information about membership types, features, and
        pricing can be found on our website.
        <br /> 4.2 We reserve the right to modify, add, or remove membership
        types and features at our discretion.
      </p>
      <strong>5. User Conduct</strong>
      <p>
        5.1 You agree to use the Services in accordance with all applicable laws
        and regulations. <br />
        5.2 You shall not engage in any of the following prohibited activities:
        <ul>
          <li>
            Providing false or misleading information during registration or in
            your profile.
          </li>
          <li>Harassing, threatening, or abusing other users. </li>
          <li>Sending spam, unsolicited messages, or advertisements.</li>
          <li>Using the Services for any illegal or unauthorized purpose.</li>
          <li>Uploading viruses or other malicious code.</li>
        </ul>
      </p>
      <strong>6. Privacy</strong>
      <p>
        Our Privacy Policy describes how we collect, use, and disclose your
        personal information. By using the Services, you consent to our
        collection and use of your personal information as described in our
        Privacy Policy.
      </p>
      <strong>7. Intellectual Property</strong>
      <p>
        7.1 All content, trademarks, and intellectual property rights related to
        the Services are owned by or licensed to us.
        <br /> 7.2 You are granted a limited, non-exclusive, non-transferable,
        revocable license to access and use the Services for personal,
        non-commercial purposes.
      </p>
      <strong>8. Termination</strong>
      <p>
        8.1 We reserve the right to terminate or suspend your account and access
        to the Services at our discretion, without prior notice, for any reason,
        including but not limited to a violation of this EULA.
        <br /> 8.2 You may terminate your account at any time by contacting us.
      </p>
      <strong>9. Limitation of Liability</strong>
      <p>
        To the maximum extent permitted by applicable law, Namma Kongu Vellalar
        Matrimony shall not be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues,
        whether incurred directly or indirectly, or any loss of data, use,
        goodwill, or other intangible losses, resulting from (i) your use or
        inability to use the Services; (ii) any unauthorized access to or use of
        our servers and/or any personal information stored therein; and (iii)
        any bugs, viruses, trojan horses, or the like that may be transmitted to
        or through our Services by any third party.
      </p>
      <strong>10. Governing Law</strong>
      <p>
        This EULA and any disputes arising out of or related to it shall be
        governed by and construed in accordance with the laws of [Your
        Country/State], without regard to its conflict of law principles.
      </p>
      <strong>11. Changes to this EULA</strong>
      <p>
        We reserve the right to modify this EULA at any time. We will provide
        notice of any changes by posting the updated EULA on our website. Your
        continued use of the Services after the effective date of any changes
        constitutes your acceptance of the new terms.
      </p>
      <strong>12. Contact Information</strong>
      <p>
        If you have any questions or concerns about this EULA, please contact us
        at:
      </p>
      <p>Namma Kongu Vellalar Matrimony,</p>
      <p>Email: nammakonguvelllalarmatrimony@gmail.com</p>
      <p>Phone: +91 7010493235</p>
      <p>
        By ticking the "I Agree" checkbox and using our Services, you
        acknowledge that you have read, understood, and agree to be bound by
        this EULA.
      </p>
    </div>
  );
};

export default TermsAndConditions;
