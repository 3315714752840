import React from "react";
import { Button, Col, Form, Image, Input, Modal, Row } from "antd";

import reportImage from "../../image/report.jpg";

const ProfileReport = (properties) => {
  const { isReport, isLoading, handleReportSend, handleReportCancel } =
    properties;

  return (
    <Modal
      centered
      footer={null}
      destroyOnClose
      open={isReport}
      closeIcon={null}
      maskClosable={false}
      title="Report This Profile"
      className="profile-report-modal"
    >
      <Image preview={false} src={reportImage} alt="report-image" />
      <Form layout="vertical" requiredMark={false} onFinish={handleReportSend}>
        <Form.Item
          name="report"
          rules={[
            {
              required: true,
              message: "Please enter your issue",
            },
            {
              min: 50,
              message: "Please enter atleast 50 characters",
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <Input.TextArea
            rows={5}
            count={{
              show: true,
            }}
            maxLength={500}
            placeholder="We're sorry for the trouble. Please provide the details of your report."
          />
        </Form.Item>
        <Row justify="center" gutter={8}>
          <Col>
            <Button type="primary" htmlType="submit" loading={isLoading === 3}>
              Send
            </Button>
          </Col>
          {isLoading !== 3 ? (
            <Col>
              <Button danger onClick={handleReportCancel}>
                Cancel
              </Button>
            </Col>
          ) : null}
        </Row>
      </Form>
    </Modal>
  );
};

export default ProfileReport;
