import { Typography } from "antd";
import "./style.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <Typography.Title level={2}>Privacy Policy</Typography.Title>
      <p>
        <strong>Effective Date:</strong> 07/Jul/2024
      </p>
      <p>
        <strong>Introduction</strong>
      </p>
      <p>
        Welcome to <strong>Namma Kongu Vellalar Matrimony</strong>, a matrimony
        website dedicated to helping you find your life partner. Your privacy is
        of utmost importance to us. This Privacy Policy outlines how we collect,
        use, share, and protect your personal information.
      </p>
      <p>
        <strong>1. Information We Collect</strong>
      </p>
      <p>
        We collect various types of information to provide and improve our
        services. This includes:
      </p>
      <p>
        <strong>1.1 Personal Information:</strong>
      </p>
      <ul>
        <li>
          <strong>Gender</strong>
        </li>
        <li>
          <strong>Date of Birth</strong>
        </li>
        <li>
          <strong>Marital Status</strong>
        </li>
        <li>
          <strong>Height</strong>
        </li>
        <li>
          <strong>Weight</strong>
        </li>
        <li>
          <strong>Body Type</strong>
        </li>
        <li>
          <strong>Skin Color</strong>
        </li>
        <li>
          <strong>Education Level and Details</strong>
        </li>
        <li>
          <strong>Job Title and Details</strong>
        </li>
        <li>
          <strong>Dietary Preferences</strong>
        </li>
        <li>
          <strong>Blood Group</strong>
        </li>
      </ul>
      <p>
        <strong>1.2 Sensitive Information:</strong>
      </p>
      <ul>
        <li>
          <strong>Kovil Name</strong>
        </li>
        <li>
          <strong>Kovil Location</strong>
        </li>
        <li>
          <strong>Physically Challenged Status</strong>
        </li>
        <li>
          <strong>Description of Disabilities</strong>
        </li>
        <li>
          <strong>Value of Assets</strong>
        </li>
        <li>
          <strong>Description of Assets</strong>
        </li>
      </ul>
      <p>
        <strong>2. How We Use Your Information</strong>
      </p>
      <p>The information we collect is used for the following purposes:</p>
      <ul>
        <li>To create and manage your profile.</li>
        <li>To match you with compatible profiles.</li>
        <li>To improve our services and website functionality.</li>
        <li>To communicate with you regarding your account or our services.</li>
        <li>To ensure compliance with legal and regulatory requirements.</li>
      </ul>
      <p>
        <strong>3. Sharing Your Information</strong>
      </p>
      <p>We may share your information with:</p>
      <ul>
        <li>
          <strong>Potential Matches:</strong> To help you find compatible
          profiles.
        </li>
        <li>
          <strong>Service Providers:</strong> To assist in providing our
          services (e.g., hosting providers, payment processors).
        </li>
        <li>
          <strong>Legal Authorities:</strong> When required by law or to protect
          our rights.
        </li>
      </ul>
      <p>
        <strong>4. Security of Your Information</strong>
      </p>
      <p>
        We take the security of your personal information seriously and
        implement appropriate technical and organizational measures to protect
        it from unauthorized access, loss, misuse, or alteration.
      </p>
      <p>
        <strong>5. Your Rights</strong>
      </p>
      <p>You have the right to:</p>
      <ul>
        <li>Access your personal information.</li>
        <li>Request correction or deletion of your personal information.</li>
        <li>Object to the processing of your personal information.</li>
        <li>
          Withdraw consent for the collection and processing of your personal
          information.
        </li>
      </ul>
      <p>
        <strong>6. Cookies and Tracking Technologies</strong>
      </p>
      <p>
        We use cookies and similar tracking technologies to enhance your
        experience on our website. You can control cookies through your browser
        settings.
      </p>
      <p>
        <strong>7. Changes to This Privacy Policy</strong>
      </p>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on our website. Your
        continued use of our services after any changes indicates your
        acceptance of the new terms.
      </p>
      <p>
        <strong>8. Contact Us</strong>
      </p>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <p>nammakonguvellalarmatrimony@gmail.com</p>
      <p>+91 7010493235</p>
      <p>
        <strong>9. Children’s Privacy</strong>
      </p>
      <p>
        Our services are not intended for individuals under the age of 18. We do
        not knowingly collect personal information from children under 18. If we
        become aware that we have collected such information, we will take steps
        to delete it.
      </p>
      <p>
        <strong>10. International Data Transfers</strong>
      </p>
      <p>
        Your information may be transferred to and maintained on servers located
        outside your state, province, country, or other governmental
        jurisdiction where data protection laws may differ from those in your
        jurisdiction. We ensure that such transfers comply with applicable data
        protection laws.
      </p>
      <p>
        <strong>11. Third-Party Links</strong>
      </p>
      <p>
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or the content of those websites.
        Please review the privacy policies of these third-party sites.
      </p>
      <p>
        <strong>12. User Generated Content</strong>
      </p>
      <p>
        Any content you post on our website, such as profile information or
        messages, may be available to other users of the website. Please be
        cautious about the information you choose to share publicly.
      </p>
      <p>
        By using our website and services, you agree to the terms of this
        Privacy Policy. Thank you for trusting Namma Kongu Vellalar Matrimony
        with your personal information.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
