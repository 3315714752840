import maleProfilePlaceholder from "../image/male-profile-placeholder.jpg";
import femaleProfilePlaceholder from "../image/female-profile-placeholder.jpg";

const placeholder = {
  Male: maleProfilePlaceholder,
  Female: femaleProfilePlaceholder,
};

export const getProfileImageUrl = (data, isProfile) => {
  const { basic_details, other_details, profile_details } = data;

  if (isProfile && other_details.profile_photo_list.length) {
    return other_details.profile_photo_list[0];
  }

  if (!isProfile && basic_details?.profile_image_url) {
    return basic_details.profile_image_url;
  }

  return placeholder[basic_details?.gender || profile_details?.gender];
};
