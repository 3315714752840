import React from "react";
import "./style.scss";
import { Typography } from "antd";

const About = () => {
  return (
    <div className="about-container">
      <Typography.Title level={2} className="title">
        About NKVG Matrimony
      </Typography.Title>
      <Typography.Title level={4}>
        Welcome to Namma Kongu Vella Gounder Matrimony
      </Typography.Title>
      <p>
        At NKVG Matrimony (Namma Kongu Vella Gounder Matrimony), our focus is on
        helping members of the Kongu Vella Gounder community to find their ideal
        life partners. More than just a matrimonial site, our platform serves as
        a bridge connecting individuals who share similar values, traditions,
        and aspirations. We recognize the importance of marriage within Kongu
        culture and are dedicated to preserving these traditions while embracing
        the benefits of modern matchmaking.
      </p>
      <b>Our Mission</b>
      <p>
        Our mission is to provide a trusted and secure environment where members
        of the community can find, connect, and form meaningful relationships
        that lead to marriage. We aim to empower individuals to find their ideal
        partners while honouring the rich cultural heritage that defines the
        Kongu Vella Gounder identity.
      </p>
      <b>Our Vision</b>
      <p>
        We aspire to strengthen the Kongu Vella Gounder community by ensuring
        that every individual can meet a life partner who understands and
        respects their values. By addressing the unique needs of our community,
        we strive to preserve our traditions and customs for future generations.
      </p>
      <b>Who We Are</b>
      <p>
        NKVG Matrimony Services Pvt Ltd was founded by Venkatesh Brabhu, whose
        passion for serving the Kongu Vella Gounder community will be reflected
        in every aspect of our platform. With a deep understanding of cultural
        value and the importance of family, the founder has created a space that
        honours these values. Our team of experienced professionals in
        technology, and customer service is committed to providing you with the
        best possible experience.
      </p>
      <b>What We Do</b>
      <p>
        We offer a comprehensive range of services designed to make your search
        for a life partner as seamless as possible. From advanced profile
        matching to personalised consultations, our platform caters to your
        unique needs. Every profile on Namma Kongu Vella Gounder Matrimony (NKVG
        Matrimony) is verified to ensure authenticity, allowing you to connect
        with genuine individuals who share your values and goals.
      </p>
      <b>Our Commitment</b>
      <p>
        We priorities your privacy and security, taking every measure to protect
        your personal information and ensure that every interaction on our site
        is safe and secure. Our platform is designed to be user-friendly, making
        it easy to navigate, search for matches, and manage your profile.
        Additionally, our dedicated customer support team is always available to
        assist you throughout your journey.
      </p>
    </div>
  );
};

export default About;
