import React from 'react';
import { useParams } from 'react-router-dom';

const Detail = () => {

  const params = useParams();

  return (
      <div className="y-wrap">Detail {params.id}</div>
  );
};

export default Detail;
